import React from "react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import productImg1 from "./images/product-img-1.png";
import GetinTouch from "./GetinTouch";

import comingSoon from "./comingsoonpdf";

//Simplex-images:
import heavyDuty from "./images/simplex/Heavy-Duty-Inline.png";
import Fabricated from "./images/simplex/Inline-Fabricated.png";
import hightPressure from "./images/simplex/Inline-High-Pressure.png";
import LowPressureEasySerice from "./images/simplex/Inline-Low-Pressure-Easy-Service.png";
import Manifold from "./images/simplex/Manifold.png";
import LowPressure from "./images/simplex/Inline-Low-Pressure.png";

//Duplex:
import LowPressureduplex from "./images/Duplex/Low-Pressure-Duplex.png";
import HighPressureduplex from "./images/Duplex/High-Pressure--Duplex.png";
import easyServiceduplex from "./images/Duplex/Low-Pressure-Easy-Service-Duplex.png";
import Fabricatedduplex from "./images/Duplex/Low-Pressure-Fabricated- Ball-Valve-Duplex.png";
import lasrgeVolumeduplex from "./images/Duplex/Low-Pressure-Large-Volume-Duplex.png";

//ReturnLine:
import tanktop from "./images/ReturnLine/Simplex-Tank-Top-Return-line.png";
import lineinline from "./images/ReturnLine/Inline-Return-line.png";
import Fabricatedreturnline from "./images/ReturnLine/Simplex-Low-Pressure-Fabricated-Return-line.png";
import returnduplextanktop from "./images/ReturnLine/Duplex-Tank-Top-Return-line.png";
import Duplexsimplexfabricated from "./images/ReturnLine/Duplex-Low-Pressure-Fabricated-Return-line.png";

//Simplex-pdf
import inline from "./images/FilterPdf/Low-Pressure-Inline-LE-02-001-R9.pdf";
import Heavy from "./images/FilterPdf/Heavy-Duty-Inline-OFM_02-002-R5.pdf";
import InlineLowPressureEasyService from "./images/FilterPdf/Inline-Filters-40-FLE-0020-0270.pdf";
import LowPressureFabricated from "./images/simplex/Inline-Low-Pressure-Fabricated.pdf"; //new
import InlinehighPressure from "./images/FilterPdf/High-Pressure-Inline-LE-04-001-R9.pdf";
import HighPressureManifold from "./images/FilterPdf/High-Pressure-Manifold-FE-04-002-R9.pdf";

//pdf for -Duplex
import duplexhightPressure from "./images/FilterPdf/High-Pressure-Duplex-Filters-250-450-LD.pdf";
import duplexLowPressureDuplex from "./images/FilterPdf/Low-Pressure-Duplex-LD-05-001-R9.pdf";
import duplexLowPressureEasySerice from "./images/FilterPdf/25-FLD.pdf";
import dupelxLowPressureFabricatedDuplex from "./images/FilterPdf/Low-Pressure-Duplex-DK-06-001-R3.pdf";

//pdf for - Return Line
import DuplexTankTop from "./images/FilterPdf/Duplex-Return-Line-FRD-08-001-R3.pdf";
import SimpleLowpressureFab from "./images/ReturnLine/Simplex-Low-Pressure-Fabricated.pdf";
import SimplexReturnlineInline from "./images/FilterPdf/Return-Line-FR-L-07-002-R4.pdf";
import SimplexTankTop from "./images/FilterPdf/Return-Line-FRE-07-001-R5.pdf";

//pdf fro -OfflineFiltrationSystem
import OfflineFiltrationSystem from "./images/FilterPdf/Offline-System-NFF2-Nov-11.pdf";

//OfflineFiltrationSystem
import OfflineFiltrationSystemImg from "./images/OfflineFiltrationSystem/Offline-Filtration-System.png";

//pdf for -Breather
import Breather from "./images/FilterPdf/Breathers-TLF-Jan-12.pdf";

//Breather
import BreatherImg from "./images/Breather/Breather-TLF.png";

//pdf for -FilterElement
import FilterElement from "./images/FilterPdf/Filter-Elements-10.001-R7.pdf";

//FilterElement
import FilterElementImg from "./images/FilterElement/filter-element-img-1.png";
import FilterElementImg2 from "./images/FilterElement/Filter-Element.png";

// Sample item descriptions for demonstration
const itemDescriptions = {
  SimplexInlineLowPressure: {
    name: "Inline Low Pressure",
    description:
      "222 The 40/160-LE/LEN series Filters are used for direct installation in the pipeline and provide wear protection of downstream components and systems",
    bannerImage: LowPressure,
    pdf: inline,
    details: {
      basicDetails: [
        "Design: Simplex Inline",
        "Flow Capacity : 50 to 450 lpm, (13 to 100 gpm)",
        "Max Pressure (PS) : 40 bar [580 psi] , 160 bar [2321 psi].",
        "Test Pressure (TP) :   1.43 x PS (as per CE/PED) , 1.3 x PS (as per ASME).",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range :-20 to +100 C (Standard) ,-4 F to +212 F (Standard)",
        "Connection :  Upto G1-1/2” / SAE-24",
        "Element design :  LE Series - EPE standard , LEN Series - As per DIN-24550",
        "Type :  40/160-LE/LEN",
      ],
      materialOfConstruction: [
        "Head:  : Aluminium Alloy.",
        "Bowl: Carbon Steel.",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint:  Bowl Externally painted in RAL-5010 , Others on request.",
      ],
      flowCapacity: [
        "0005 / 0040 50 lpm [13 gpm]",
        "0008 / 0063 80 lpm [20 gpm]",
        "0013 / 0100 130 lpm [35 gpm]",
        "0015 150 lpm [40 gpm]",
        "0018 180 lpm [45 gpm]",
        "0020 / 0160 200 lpm [50 gpm]",
        "0030 / 0250 300 lpm [65 gpm]",
        "0045 / 0400 450 lpm [100 gpm]",
      ],
    },
  },
  SimplexHeavyDutyInline: {
    name: "Heavy Duty Inline",
    description:
      "The 160-OFM20X series Filter are designed exclusively for heavy duty applications and used for direct installation in the pipeline to provide wear protection of downstream components and systems",
    bannerImage: heavyDuty,
    pdf: Heavy,
    details: {
      basicDetails: [
        "Design: Simplex Inline",
        "Flow Capacity : 200 to 450 lpm, (50 to 100 gpm)",
        "Max Pressure (PS) : 160 bar [2321 psi].",
        "Test Pressure (TP) :  1.43 x PS (as per CE/PED) , 1.3 x PS (as per ASME).",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range :-20 to +100 C (Standard) , -4 F to +212 F (Standard).",
        "Connection :  SAE 1-1/2” / G1-1/2”.",
        "Element design : : EPE standard",
        "Type : 160-OFM20X",
      ],
      materialOfConstruction: [
        "Head:  : GGG40/GGG50.",
        "Bowl: Carbon Steel.",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint: Bowl Externally painted in RAL-5010 , Others on request.",
      ],
      flowCapacity: [
        "0020 200 lpm [50 gpm]",
        "0030 300 lpm [65 gpm]",
        "0045 450 lpm [100 gpm]",
      ],
    },
  },
  SimplexInlineLowPressureEasyService: {
    name: "Inline Low Pressure Easy Service",
    description:
      "These Easy maintenance design filters are used for direct installation in the pipeline and provide wear protection of downstream components and systems. ",
    bannerImage: LowPressureEasySerice,
    pdf: InlineLowPressureEasyService,
    details: {
      basicDetails: [
        "Design: Simplex Inline",
        "Flow Capacity : 200 to 2700 lpm, (50 to 700 gpm)",
        "Max Pressure (PS) : 40 bar [580 psi],",
        "Test Pressure (TP) :  1.43 x PS (as per CE/PED) ,1.3 x PS (as per ASME)",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range : -20 to +100 C (Standard) ,-4 F to +212 F (Standard)",
        "Connection :  Upto 4” ",
        "Element design : FLE Series - EPE standard , FLEN Series - As per DIN-24550",
        "Type : 40 FLE/FLEN",
      ],
      materialOfConstruction: [
        "Head and cover:  : Aluminium Alloy",
        "Tube: Carbon Steel.",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        " Paint: Tube Externally painted in RAL-5010 , Others on request.",
      ],
      // flowCapacity: [
      //  "Content Will be Posted Soon..."
      // ],
    },
  },
  SimplexInlineLowPressureFabricated: {
    name: "Inline Low Pressure Fabricated",
    description:
      "These filters in fabricated design are for inline mounting and provide wear protection of downstream components and systems. ",
    bannerImage: Fabricated,
    // pdf: LowPressureFabricated,
    pdf: comingSoon,
    details: {
      basicDetails: [
        "Design: Simplex Inline",
        "Flow Capacity : 200 to 2950 lpm, (50 to 750 gpm)",
        "Max Pressure (PS) : 10 BAR [145 psi],25 BAR [362.5 psi],100 BAR [1450 psi]",
        "Test Pressure (TP) :  11.43 x PS (as per CE/PED),1.3 x PS (as per ASME)",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range : -20 to +100 C (Standard) ,-4 F to +212 F (Standard)",
        "Connection :  Upto DN100",
        "Element design :  EPE standard and as per DIN-24550",
        "Type :10/25/100-L",
      ],
      materialOfConstruction: [
        "Body: Carbon Steel / Stainless Steel",

        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint: Body Externally painted in RAL-5010 , Others on request.",
      ],
      // flowCapacity: [
      //   "Content Will be Posted Soon..."
      // ],
    },
  },
  SimplexInlineHighPressure: {
    name: "Inline High Pressure",
    description:
      "The 250/450-LE/LEN series Filters are used for direct installation in the pipeline and provide wear protection of downstream components and systems",
    bannerImage: hightPressure,
    pdf: InlinehighPressure,
    details: {
      basicDetails: [
        "Design: Simplex Inline",
        "Flow Capacity : 50 to 1450 lpm, (13 to 380 gpm)",
        "Max Pressure (PS) : 250 bar [3625 psi] ,450 bar [6525 psi]. ",
        "Test Pressure (TP) :  1.43 x PS (as per CE/PED) , 1.3 x PS (as per ASME)",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range : -20 to +100 C (Standard) ,-4 F to +212 F (Standard)",
        "Connection : Upto SAE 2-1/2” / SAE-32",
        "Element design :LE Series - EPE standard ,LEN Series - As per DIN-24550",
        "Type :250_450 LE/LEN",
      ],
      materialOfConstruction: [
        "Head:  : GGG50",
        "Bowl: Carbon Steel",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint: Bowl Externally painted in RAL-5010 Others on request",
      ],
      // flowCapacity: [
      //   "Content Will be Posted Soon..."
      // ],
    },
  },
  SimplexManifoldMountedHighPressure: {
    name: "Manifold Mounted High Pressure",
    description:
      "The 250/450-FE/FEN series Filters are used for direct manifold mounting avoiding piping and provide wear protection of downstream components & systems.",
    bannerImage: Manifold,
    pdf: HighPressureManifold,
    details: {
      basicDetails: [
        "Design : Simplex Manifold mounting.",
        "Flow Capacity : 50 to 1450 lpm, (13 to 380 gpm)",
        "Max Pressure (PS) : 250 bar [3625 psi] , 450 bar [6525 psi].",
        "Test Pressure (TP) :  1.43 x PS (as per CE/PED) , 1.3 x PS (as per ASME).",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range : -20 C to +100 C (Standard) , -4 F to +212 F (Standard).",
        "Element design : FE Series - EPE standard , FEN Series - As per DIN-24550",
        "Connection : Upto DN50.",
        "Type : 250_450 FE/FEN.",
      ],
      materialOfConstruction: [
        "Head:  GGG50.",
        "Bowl: Carbon Steel.",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint: Bowl Externally painted in RAL-5010.",
      ],
      flowCapacity: [
        "0005 / 0040 50 lpm [13 gpm]",
        "0008 / 0063 80 lpm [20 gpm]",
        "0013 / 0100 130 lpm [35 gpm]",
        "0015     150 lpm [40 gpm]",
        "0018 180 lpm [45 gpm]",
        "0020 / 0160 200 lpm [50 gpm]",
        "0030 / 0250 300 lpm [65 gpm]",
        "0045 / 0400 450 lpm [100 gpm]",
        "0095 / 0630 950 lpm [250 gpm]",
        "0145 / 1000 1450 lpm [380 gpm]",
      ],
    },
  },

  // duplex
  DuplexLowPressure: {
    name: "Low Pressure",
    description:
      "111The 40/160-LD/LDN series Filters are used for direct installation in the pipeline and provide wear protection of downstream components & systems.",
    bannerImage: LowPressureduplex,
    pdf: duplexLowPressureDuplex,
    details: {
      basicDetails: [
        "Design : Duplex Inline",
        "Flow Capacity : 50 to 450 lpm, (13 to 100 gpm)",
        "Max. Pressure (PS): 40 bar [580 psi]  , 160 bar [2321 psi]",
        "Test Pressure (TP) :1.43 x PS (as per CE/PED) 1.3 x PS (as per ASME)",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range : -20 to +100 C (Standard) -4 F to +212 F (Standard)",
        "Connection : Upto SAE 1-1/2” / SAE-32",
        "Element design : LD Series - EPE standard , LDN Series - As per DIN-24550",
        "Type:  40/160-LD/LDN",
      ],
      materialOfConstruction: [
        "Head : GGG50. ",
        "Bowl:Carbon Steel. ",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint :Bowl Externally painted in RAL-5010, Others on request. ",
      ],
      flowCapacity: [
        "0005 / 0040 50 lpm [13 gpm]",
        "0008 / 0063 80 lpm [20 gpm]",
        "0013 / 0100 130 lpm [35 gpm]",
        "0015 150 lpm [40 gpm]",
        "0018 180 lpm [45 gpm]",
        "0020 / 0160 200 lpm [50 gpm]",
        "0030 / 0250 300 lpm [65 gpm]",
        "0045 / 0400 450 lpm [100 gpm]",
      ],
    },
  },
  DuplexHighPressure: {
    name: "High Pressure",
    description:
      "The 250/450-LD/LDN series Filters are used for direct installation in the pipeline and provide wear protection of downstream components & systems.",
    bannerImage: HighPressureduplex,
    pdf: duplexhightPressure,
    details: {
      basicDetails: [
        "Design : Duplex Inline",
        "Flow Capacity : 50 to 1450 lpm, (13 to 380 gpm)",
        "Max. Pressure (PS): 250 bar [3625 psi] , 450 bar [6525 psi]. ]",
        "Test Pressure (TP) : 1.43 x PS (as per CE/PED) , 1.3 x PS (as per ASME)",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range :-10°C up to +100°C",
        "Connection : Upto SAE 1-1/2” / SAE-32",
        "Element design : LD Series - EPE standard , LDN Series - As per DIN-24550",
        "Type: 250/450-LD/LDN",
      ],
      materialOfConstruction: [
        "Head : GGG50. ",
        "Bowl:Carbon Steel. ",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint :Bowl Externally painted in RAL-5010, Others on request. ",
      ],
      flowCapacity: [
        "0005 / 0040 50 lpm [13 gpm]",
        "0008 / 0063 80 lpm [20 gpm]",
        "0013 / 0100 130 lpm [35 gpm]",
        "0015 150 lpm [40 gpm]",
        "0018 180 lpm [45 gpm]",
        "0020 / 0160 200 lpm [50 gpm]",
        "0030 / 0250 300 lpm [65 gpm]",
        "0045 / 0400 450 lpm [100 gpm]",
      ],
    },
  },
  DuplexLowPressureEasyService: {
    name: "Low Pressure Easy Service",
    description:
      "These Easy maintenance design filters are used for direct installation in the pipeline and provide wear protection of downstream components and systems.",
    bannerImage: easyServiceduplex,
    pdf: duplexLowPressureEasySerice,
    details: {
      basicDetails: [
        "Design : Duplex Inline",
        "Flow Capacity : 200 to 2700 lpm, (50 to 700 gpm)",
        "Max. Pressure (PS): 40 bar [580 psi],",
        "Test Pressure (TP) : 1.43 x PS (as per CE/PED) , 1.3 x PS (as per ASME)",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range :-20 to +100 C (Standard) , -4 F to +212 F (Standard)",
        "Connection :  Upto DN100",
        "Element design : FLD Series - EPE standard , FLDN Series - As per DIN-24550",
        "Type: 40-FLD/FLDN",
      ],
      materialOfConstruction: [
        "Head : Aluminium Alloy. ",
        "Bowl:Carbon Steel. ",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint :Bowl Externally painted in RAL-5010, Others on request. ",
      ],
      flowCapacity: [
        "0005 / 0040 50 lpm [13 gpm]",
        "0008 / 0063 80 lpm [20 gpm]",
        "0013 / 0100 130 lpm [35 gpm]",
        "0015 150 lpm [40 gpm]",
        "0018 180 lpm [45 gpm]",
        "0020 / 0160 200 lpm [50 gpm]",
        "0030 / 0250 300 lpm [65 gpm]",
        "0045 / 0400 450 lpm [100 gpm]",
      ],
    },
  },
  DuplexLowPressureFabricatedBallValve: {
    name: "Low Pressure Fabricated - Ball Valve",
    description:
      "Designed as per PED / ASME / Standard Engineering Practice, based on project / product requirement, the DK series Filters are used for direct installation in the pipeline and provide wear protection of downstream components & systems.",
    bannerImage: Fabricatedduplex,
    pdf: dupelxLowPressureFabricatedDuplex,
    details: {
      basicDetails: [
        "Design :Duplex ",
        "Flow Capacity : 200 to 2950 lpm, (50 to 750 gpm)",
        "Max. Pressure (PS): 16 bar [232 psi] ,40 bar [580 psi] , 45 bar [652 psi] ,60 bar [870 psi]",
        "Test Pressure (TP) : 1.3 / 1.43 / 1.5 or , as per relevant standards",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range :  -20 to +100 C (Standard) , -4 F to +212 F (Standard)",
        "Connection : Upto 6” ANSI ",
        "Element design : EPE standard",
        "Type:  16/40/45/60-DK",
      ],
      materialOfConstruction: [
        "Housing : Carbon Steel / Stainless Steel",
        "C/O Vlv : Carbon Steel / Stainless Steel",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint : Externally painted in RAL-5010 ,Others on request.",
      ],
      flowCapacity: [
        "0020 200 lpm [50 gpm]",
        "0030 300 lpm [65 gpm]",
        "0045 450 lpm [100 gpm]",
        "0060 600 lpm [150 gpm]",
        "0095 950 lpm [250 gpm]",
        "0120 1200 lpm [300 gpm]",
        "0145 1450 lpm [380 gpm]",
        "0200 2000 lpm [500 gpm]",
        "0270 2700 lpm [700 gpm]",
        "0297 2950 lpm [750 gpm]",
      ],
    },
  },
  DuplexLowPressureLargeVolume: {
    name: "Low Pressure Large Volume",
    description:
      "These filters in fabricated design are for inline mounting and provide wear protection of downstream components and systems.",
    bannerImage: lasrgeVolumeduplex,
    // pdf: "apple3.pdf",
    pdf: comingSoon,
    details: {
      basicDetails: [
        "Design :Duplex",
        "Flow Capacity : upto 13500 lpm  (3550 gpm)",
        "Max. Pressure (PS): 16 bar [232 psi] ,25 bar [365 psi]",
        "Test Pressure (TP) : 1.3 / 1.43 / 1.5 or , as per relevant standards",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range :  -20 to +100 C (Standard) , -4 F to +212 F (Standard)",
        "Connection : Upto 12” ANSI",
        "Element design : EPE standard",
        "Type:  16/25-FLD",
      ],
      materialOfConstruction: [
        "Housing : Carbon Steel / Stainless Steel",
        "C/O Vlv : Carbon Steel / Stainless Steel",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint : Externally painted in RAL-5010 ,Others on request.",
      ],
      flowCapacity: ["Content Will be Posted Soon..."],
    },
  },

  //ReturnLine:
  ReturnLineSimplexTankTop: {
    name: "Simplex Tank Top",
    description:
      "The 10-FRE series Filters are used for tank top mounting in the return line of the system and provide protection for tank and operating pumps",
    bannerImage: tanktop,
    pdf: SimplexTankTop,
    details: {
      basicDetails: [
        "Design: Simplex Tank Top Mounting",
        "Flow Capacity : 50 to 180 lpm, (13 to 45 gpm)",
        // "Application: Suitable for low-pressure systems",
        "Max.Pressure (PS) : 10 bar [145 psi]",
        "Test Pressure (PT) : 1.43 x PS (as per CE/PED), 1.3 x PS (as per ASME)",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range : -20 to +100 C (Standard) , -4 F to +212 F (Standard)",
        "Connection :  Upto 1-1/2” BSP(F)/ SAE-20",
        "Element design : EPE standard",
        "Type: 10-FRE",
      ],
      materialOfConstruction: [
        "Head:  Aluminium Alloy.",
        "Cover : Carbon Reinforced Plastic.",
        "Bowl: Carbon Reinforced Plastic.",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint : NA",
      ],
      flowCapacity: [
        "0005 50 lpm [13 gpm]",
        "0008 80 lpm [20 gpm]",
        "0013 130 lpm [35 gpm]",
        "0015 150 lpm [40 gpm]",
        "0018 180 lpm [45 gpm]",
      ],
    },
  },
  ReturnLineSimplexReturnLineInline: {
    name: "Simplex Return Line/Inline",
    description:
      "The FR/L & FR/LN series Filters are used for tank top mounting in the return line of the system and provide protection",
    bannerImage: lineinline,
    pdf: SimplexReturnlineInline,
    details: {
      basicDetails: [
        "Design : Simplex Tank top/Line mounting",
        "Flow Capacity : 200 to 2700 lpm, (50 to 700 gpm)",
        "Max. Pressure (PS): 16 bar [232 psi] ",
        "Test Pressure (TP) :  1.43 x PS (as per CE/PED) , 1.3 x PS (as per ASME)",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range :  -20 to +100 C (Standard) , -4 F to +212 F (Standard)",
        "Connection : Upto SAE 4” / SAE-64",
        "Element design : FR/L Series - EPE standard , FR/LN Series - As per DIN-24550",
        "Type:  16-FR/L / FR/LN",
      ],
      materialOfConstruction: [
        "Cover : Aluminium Alloy",
        "Housing : Aluminium Alloy",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint : NA",
      ],
      flowCapacity: [
        "0020 / 0160 200 lpm [50 gpm]",
        "0030 / 0250 300 lpm [65 gpm]",
        "0045 450 lpm [100 gpm]",
        "0060 / 0400 600 lpm [150 gpm]",
        "0095 / 0630 950 lpm [250 gpm]",
        "0120 1200 lpm [300 gpm]",
        "0145 / 1000 1450 lpm [380 gpm]",
        "0200 2000 lpm [525 gpm]",
        "0270 2700 lpm [700 gpm]",
      ],
    },
  },
  ReturnLineSimplexLowPressureFabricated: {
    name: "Simplex Low Pressure Fabricated",
    description:
      "These filters in fabrication design are for tank top mounting and ensure filtration of the return flow from the system",
    bannerImage: Fabricatedreturnline,
    // pdf: SimpleLowpressureFab,
    pdf: comingSoon,
    details: {
      basicDetails: [
        "Design : Simplex Tank Top-Fabricated",
        "Flow Capacity : 200 to 2700 lpm, (50 to 700 gpm)",
        "Max. Pressure (PS): 10 bar [145 psi] , 16 bar [232 psi]",
        "Test Pressure (TP) :  1.43 x PS (as per CE/PED) , 1.3 x PS (as per ASME)",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range :  -20 to +100 C (Standard) , -4 F to +212 F (Standard)",
        "Connection : Upto DN100",
        "Element design : EPE standard",
        "Type:  10_16 RL ",
      ],
      materialOfConstruction: [
        "Cover : Carbon Steel ",
        "Housing : Carbon Steel ",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint : Externally painted in RAL-5010 , Others on request.",
      ],
      // flowCapacity: [
      //   "0020 / 0160 200 lpm [50 gpm]",
      //   "0030 / 0250 300 lpm [65 gpm]",
      //   "0045 450 lpm [100 gpm]",
      //   "0060 / 0400 600 lpm [150 gpm]",
      //   "0095 / 0630 950 lpm [250 gpm]",
      //   "0120 1200 lpm [300 gpm]",
      //   "0145 / 1000 1450 lpm [380 gpm]",
      //   "0200 2000 lpm [525 gpm]",
      //   "0270 2700 lpm [700 gpm]",
      // ]
    },
  },
  ReturnLineDuplexTankTop: {
    name: "Duplex Tank Top",
    description:
      "The 10-FRD series Filters are used for tank top mounting in the return line of the system for continuous operation",
    bannerImage: returnduplextanktop,
    pdf: DuplexTankTop,
    details: {
      basicDetails: [
        "Design: Duplex Tank Top Mounting",
        "Flow Capacity : 50 to 180 lpm, (13 to 45 gpm)",
        // "Application: Suitable for low-pressure systems",
        "Max.Pressure (PS) : 10 bar [145 psi]",
        "Test Pressure (PT) :  1.43 x PS (as per CE/PED) ,1.3 x PS (as per ASME)",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range :  -20 to +100 C (Standard) , -4 F to +212 F (Standard)",
        "Connection : Upto 1-1/2” BSP(F)/ SAE-20",
        "Element design : EPE standard",
        "Type: 10 FRD",
      ],
      materialOfConstruction: [
        "Head:  Aluminium Alloy.",
        "Cover : Carbon Reinforced Plastic.",
        "Bowl: Carbon Reinforced Plastic.",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint : NA",
      ],
      // flowCapacity: [
      //  "0005 50 lpm [13 gpm]",
      //  "0008 80 lpm [20 gpm]",
      //  "0013 130 lpm [35 gpm]",
      //  "0015 150 lpm [40 gpm]",
      //  "0018 180 lpm [45 gpm]",
      // ]
    },
  },
  ReturnLineDuplexLowPressureFabricated: {
    name: "Duplex Low Pressure Fabricated",
    description:
      "These filters in fabrication design are for tank top mounting and ensure filtration of the return flow from the system",
    bannerImage: Duplexsimplexfabricated,
    // pdf: "apple4.pdf",
    pdf: comingSoon,
    details: {
      basicDetails: [
        "Design: Duplex Low Pressure Fabricated",
        "Flow Capacity : 200 to 2950 lpm, (50 to 750 gpm)",
        // "Application: Suitable for low-pressure systems",
        "Max.Pressure (PS) : 10 bar [145 psi] ,16 bar [232 psi]",
        "Test Pressure (PT) : 1.43 x PS (as per CE/PED) ,  1.3 x PS (as per ASME)",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Temperature range :  -20 to +100 C (Standard) , -4 F to +212 F (Standard)",
        "Connection : Upto DN100",
        "Element design : EPE standard",
        "Type:10_16 DR",
      ],
      materialOfConstruction: [
        "Head: Carbon Steel ",
        "Cover : Carbon Steel ",
        "Bowl: Carbon Steel ",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
        "Paint : Externally painted in RAL-5010 , Others on request.",
      ],
      // flowCapacity: [
      //  "0005 50 lpm [13 gpm]",
      //  "0008 80 lpm [20 gpm]",
      //  "0013 130 lpm [35 gpm]",
      //  "0015 150 lpm [40 gpm]",
      //  "0018 180 lpm [45 gpm]",
      // ]
    },
  },

  //OfflineFiltrationSystem
  OfflineFiltrationSystem: {
    name: "Offline Filtration System",
    description:
      "These mobile filtration systems are with integral pump-motor units and enable onsite filtration of multiple systems with single unit. Also available in multi-stage filtration and / or with online particle contamination indicator.",
    bannerImage: OfflineFiltrationSystemImg,
    pdf: OfflineFiltrationSystem,
    details: {
      basicDetails: [
        "Design: Offline Filtration System",
        "Flow Capacity : 15 to 170 lpm, (4 to 45 gpm)",
        // "Application: Suitable for low-pressure systems",
        "Max.Pressure (PS) : Pump 8 bar",
        "Test Pressure (PT) : 12 bar",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Electric Power : 1HP to 7.5HP",
        "Connection : Upto 2” ",
        "Element design : EPE standard",
        "Type: 15_170 NFF2",
      ],
      materialOfConstruction: [
        "Flow : 15 / 170 lpm",
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
      ],
      // flowCapacity: [
      //  "0005 50 lpm [13 gpm]",
      //  "0008 80 lpm [20 gpm]",
      //  "0013 130 lpm [35 gpm]",
      //  "0015 150 lpm [40 gpm]",
      //  "0018 180 lpm [45 gpm]",
      // ]
    },
  },

  //Breather
  BreatherTLF: {
    // id: 1,
    name: "Breather",
    description:
      "Tanktop Mounted breathers used for Filtration and dehumidifying of intake air for industrial systems",
    bannerImage: BreatherImg,
    pdf: Breather,
    details: {
      basicDetails: [
        "Design: Tank Top Mounting",

        // "Application: Suitable for low-pressure systems",
        "Air Flow handling capacity  : upto 1000 m3/hr",
        // "Test Pressure (PT) : 12 BAR",
        "Filtration Rating : 1 to 25 microns absolute, 5 to 1500 micron nominal",
        "Connection : Upto G3 / DN125",
        // "Flow : 15 / 170 lpm",
        // "Connection : Upto 2",
        // "Element design : EPE standard",
        "Type: TLF - I / TLF - II / TLF - III",
      ],
      materialOfConstruction: [
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
      ],
      // flowCapacity: [
      //  "0005 50 lpm [13 gpm]",
      //  "0008 80 lpm [20 gpm]",
      //  "0013 130 lpm [35 gpm]",
      //  "0015 150 lpm [40 gpm]",
      //  "0018 180 lpm [45 gpm]",
      // ]
    },
  },
  //FilterElement
  FilterElement: {
    // id: 1,
    name: "Filter Element",
    description:
      "Filter elements for installing in EPE and DIN series housings. Available in a variety of media and wide range of micron rating and Beta rated Elements",
    bannerImage: FilterElementImg,
    pdf: FilterElement,
    details: {
      basicDetails: [
        "Design: Star Pleated-Reusable/Disposable",
        //"Flow Capacity : 50 to 450 lpm, (13 to 100 gpm)",
        // "Application: Suitable for low-pressure systems",
        "Max  Allowable Differential Pressure : UP 330 BAR",
        //  "Bypass Value : UP TO 3.5 BAR",
        "Filtration grade : 1μ(Abs) to 1000μ(Nom)",
        "Hardware : Polyamide/CS(CRCA)/SS",
        // "Seals : Upto 2",
        "Media : Glass Fibre, Paper, Wire Mesh,  Non-Woven, Water-Absorbing",
        "Type: H-XL, H-XP, G, P, VS, AS",
      ],

      materialOfConstruction: [
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
      ],
      // flowCapacity: [
      //    "Content Will be Posted Soon..."
      // ]
    },
  },

  //FilterElement
  FilterElementCrossReference: {
    // id: 1,
    name: "Filter Element - Cross Reference",
    description:
      "Filter elements for installing in other make filter housings. Available in a variety of media and wide range of micron rating and Beta rated Elements",
    bannerImage: FilterElementImg2,
    // pdf: FilterElement,
    pdf: comingSoon,
    details: {
      basicDetails: [
        "Design: Star Pleated-Reusable/Disposable",
        //"Flow Capacity : 50 to 450 lpm, (13 to 100 gpm)",
        // "Application: Suitable for low-pressure systems",
        "Max  Allowable Differential Pressure : UP 330 BAR",
        //  "Bypass Value : UP TO 3.5 BAR",
        "Filtration grade : 1μ(Abs) to 1000μ(Nom)",
        "Hardware : Polyamide/CS(CRCA)/SS",
        // "Seals : Upto 2",
        "Media : Glass Fibre, Paper, Wire Mesh,  Non-Woven, Water-Absorbing",
        "Type: H-XL, H-XP, G, P, VS, AS",
      ],

      materialOfConstruction: [
        "Filtering Media : Glass Fiber, SS wire mesh, non-woven, paper, Aquasorb (Water absorbing media)",
        "Seals : Nitrile / Viton / EPDM / Butyl / Neoprene",
      ],
      // flowCapacity: [
      //    "Content Will be Posted Soon..."
      // ]
    },
  },
};

const AppleDescription = () => {
  const { id } = useParams(); // Get the item id from the URL
  const item = itemDescriptions[id]; // Get the specific item's data
  const navigate = useNavigate(); // Hook for navigation

  if (!item) {
    return <div>Item not found</div>;
  }

  // const handleEnquiryClick = () => {
  //   navigate("/Contact");
  // };

  const handleEnquiryClick = () => {
    navigate("/Contact", { state: { productName: item?.name } }); // Pass item name via state
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="product-dis-banner">
        <div className="wrapper">
          <div className="product-dis-content">
            <div className="product-dis-wrap">
              <div className="product-dis-1">
                <img src={item?.bannerImage} alt={item?.name} />
              </div>
              <div className="product-dis-2">
                <h1>{item?.name}</h1>
                <h6>{item?.description}</h6>
                <div style={{ marginTop: "20px" }}>
                  <a
                    href={item?.pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button-3"
                  >
                    View PDF
                  </a>

                  <button
                    onClick={handleEnquiryClick} // Navigate to ContactUs page on click
                    className="button-2 m-l-1"
                  >
                    Enquire Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Description Section */}
      <div className="product-i-sec1-bg">
        <div className="wrapper">
          <div className="product-i-sec1-wrap">
            <div className="product-i-sec1-1">
              <h3>BASIC DETAILS</h3>
              <ul>
                {item?.details?.basicDetails?.length > 0 ? (
                  item.details.basicDetails.map((detail, index) => (
                    <li key={index}>{detail}</li>
                  ))
                ) : (
                  <li>No basic details available</li>
                )}
              </ul>
            </div>
            <div className="product-i-sec1-1">
              <h3>Material of Construction</h3>
              <ul>
                {item?.details?.materialOfConstruction?.length > 0 ? (
                  item.details.materialOfConstruction.map((material, index) => (
                    <li key={index}>{material}</li>
                  ))
                ) : (
                  <li>No material of construction available</li>
                )}
              </ul>
            </div>
            {/* <div className="product-i-sec1-1">
              <h3>FLOW CAPACITY</h3>
              <ul>
                {item?.details?.flowCapacity?.length > 0 ? (
                  item.details.flowCapacity.map((capacity, index) => (
                    <li key={index}>{capacity}</li>
                  ))
                ) : (
                  <li>No flow capacity details available</li>
                )}
              </ul>
            </div> */}
          </div>
        </div>
      </div>
      <GetinTouch />
    </div>
  );
};

export default AppleDescription;
