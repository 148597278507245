import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import productImg1 from "./images/product-img-1.png";
import GetinTouch from "./GetinTouch";

//switches categories
import ScrewInpdf1 from "./images/SwitchScrewIn/PST1Rev.pdf";
import ScrewInpdf2 from "./images/SwitchScrewIn/PST2.pdf";
import ScrewInpdf3 from "./images/SwitchScrewIn/PST3Rev.pdf";
import ScrewInpdf4 from "./images/SwitchScrewIn/PSTMRev.pdf";
import ScrewInpdf5 from "./images/SwitchScrewIn/PSTY.pdf";

//screIn Images
import lockable from "./images/SwitchScrewIn/image-1.png";
import img2 from "./images/SwitchScrewIn/image2.png";
import img3 from "./images/SwitchScrewIn/image3.png";
import img4 from "./images/SwitchScrewIn/image4.png";
import img5 from "./images/SwitchScrewIn/image5.png";

// -----------
// Switch : Block
import blockpdf1 from "./images/SwitchBlock/PSB1.pdf";
import blockpdf2 from "./images/SwitchBlock/PSB2.pdf";
import blockpdf3 from "./images/SwitchBlock/PSB3.pdf";
import blockpdf4 from "./images/SwitchBlock/PSVMRev.pdf";
// import ScrewInpdf5 from "./images/SwitchScrewIn/PSTY.pdf"
//swithc Block imges
import blockimg1 from "./images/SwitchBlock/image1.png";
import blockimg2 from "./images/SwitchBlock/image2.png";
import blockimg3 from "./images/SwitchBlock/image3.png";
import blockimg4 from "./images/SwitchBlock/image4.png";

// ----------
// switch : temp pdfs
import tempdf1 from "./images/SwitchTemp/TST1Rev.pdf";
import temppdf2 from "./images/SwitchTemp/TST2.pdf";
//switch Temp
//switch Temp
import tempimg1 from "./images/SwitchTemp/image1.png";
import tempimg2 from "./images/SwitchTemp/image2.png";

const itemDescriptions = {
  PressureSwitchExternalSettinglockable: {
    name: "Pressure Switch - External Setting - lockable",
    description:
      "PST1 Series Pressure Switch opens and closes electrical circuit depending on pressure increase or decrease",
    bannerImage: lockable,
    pdf: ScrewInpdf1,
    details: {
      TechnicalData: [
        "Mechanical Connection: 1/8” BSP, 1/4” BSP, 1/8”’ BSPT, 1/4”’ BSPT ,  M10x1, M12x1.5, 1/8'' NPT, 1/4'' NPT.",
        "Reproducibility : ±3% of adjusted pressure",
        "Working Temperature  : -40°C ...+85°C [-40°F ...+185°F] ",
        "Vibration Test (DIN EN 60068-2-27) : 20g (Test Time 30 min)",
        "Shock Test (DIN EN 60068-2-27:1993) :  30g",
        "Working Cycle:   5,000,000 cycles",
        "Viscosity :   Between 10 ... 800 mm /sec",
        "Electrical Connection Feature   :   250 VAC 5A",
        "Type : PST1",
      ],
    },
  },
  PressureSwitchExternalSettinglockable2: {
    name: "Pressure Switch - External Setting - lockable",
    description:
      "PST2 series cable pressure switch opens or closes electrical circuit depending on pressure is dropping or rising",
    bannerImage: img2, //
    pdf: ScrewInpdf2, //
    details: {
      TechnicalData: [
        "Mechanical Connection: 1/8 BSP",
        "Reproducibility : ±1% of adjusted pressure",
        "Working Temperature  : -20°C...+110°C [-4°F...+230°F] ",
        "Vibration Test (DIN EN 60068-2-27) : 20g (Test Time 30 min)",
        "Shock Test (DIN EN 60068-2-27:1993) :  30g",
        "Working Cycle:   5,000,000 cycles",
        "Viscosity :   Between 10 ... 800 mm /sec",
        //  "Electrical Connection Feature   :   250 VAC 5A",
        "Type : PST2",
      ],
    },
  },
  PressureSwitchInternalSettingScrew: {
    name: "Pressure Switch - Internal Setting Screw",
    description:
      "PST3 Series Pressure Switch opens and closes electrical circuit depending on pressure increase or decrease",
    bannerImage: img3, //
    pdf: ScrewInpdf3, //
    details: {
      TechnicalData: [
        "Mechanical Connection: : 1/8” BSP, 1/4” BSP, 1/8”’ BSPT, 1/4”’ BSPT , M10x1, M12x1.5, 1/8'' NPT, 1/4'' NPT.",
        "Reproducibility : ±3% of adjusted pressure",
        "Working Temperature  : -40°C ...+85°C [-40°F ...+185°F]",
        "Vibration Test (DIN EN 60068-2-27) : 20g (Test Time 30 min)",
        "Shock Test (DIN EN 60068-2-27:1993) :  30g",
        "Working Cycle:   5,000,000 cycles",
        "Viscosity :   Between 10 ... 800 mm /sec",
        "Electrical Connection Feature   :   250 VAC 5A",
        "Type : PST3",
      ],
    },
  },
  PressureSwitchInternalSettingScrew2: {
    name: "Pressure Switch - Internal Setting Screw",
    description:
      "PSTM Series Pressure switch opens or closes electrical circuit depending on pressure is dropping or rising. ",
    bannerImage: img4, //
    pdf: ScrewInpdf4, //
    details: {
      TechnicalData: [
        "Mechanical Connection: : 1/8” BSP, 1/4” BSP, 1/8”’ BSPT, 1/4”’ BSPT , M10x1, M12x1.5, 1/8'' NPT, 1/4'' NPT",
        "Reproducibility : ±1% of adjusted pressure",
        "Working Temperature  : -40°C ...+90°C [-40°F ...+194°F]",
        "Vibration Test (DIN EN 60068-2-27) : 20g (Test Time 30 min)",
        "Shock Test (DIN EN 60068-2-27:1993) :  30g",
        "Working Cycle:   5,000,000 cycles",
        "Viscosity :   Between 10 ... 800 mm /sec",
        "Electrical Connection Feature   :   250 VAC 5A",
        "Manometer : Ø40 ",
        "Type : PSTM",
      ],
    },
  },
  PressureSwitchForWashingMc: {
    name: "Pressure Switch - For Washing M/c",
    description:
      "PSTY series pressure switch is creating a difference with compact design.",
    bannerImage: img5, //
    pdf: ScrewInpdf5, //
    details: {
      TechnicalData: [
        "Mechanical Connection: : 1/8 BSP, 1/4 BSP, 3/8 BSP",
        "Reproducibility : ±1% of adjusted pressure",
        "Protective Class : IP55",
        "Working Temperature  : -20°C ...+110°C [-4°F ...+230°F]",
        "Vibration Test (DIN EN 60068-2-27) : 20g (Test Time 30 min)",
        "Shock Test (DIN EN 60068-2-27:1993) :  30g",
        "Working Cycle:   5,000,000 cycles",
        "Viscosity :   Between 10 ... 800 mm /sec",
        "Maximum system pressure : 350 bar [5076 psi].",
        "Type :  PSTY",
      ],
    },
  },

  //Pressure Switch : Block
  PressureSwitchBasemountingVertical: {
    name: "Pressure Switch - Base mounting - Vertical",
    description:
      "PSB1 pressure switch opens or closes electrical circuit depending on pressure is dropping or rising.",
    bannerImage: blockimg1, //
    pdf: blockpdf1, //
    details: {
      TechnicalData: [
        "Mechanical Connection: : Bottom Flange",
        "Reproducibility : ±1% of adjusted pressure",
        // "Protective Class : IP55",
        "Working Temperature  :  -20°C ...+110°C [-4°F ...+230°F]",
        "Vibration Test (DIN EN 60068-2-27) :  20g (Test Time 30 min)",
        "Shock Test (DIN EN 60068-2-27:1993) :  30g",
        "Working Cycle:   5,000,000 cycles",
        "Viscosity :   Between 10 ... 800 mm /sec",
        //  "Maximum system pressure : 350 bar [5076 psi].",
        "Type :  PSB1",
      ],
    },
  },
  PressureSwitchSidemountingHorizontal: {
    name: "Pressure Switch - Side mounting - Horizontal",
    description:
      "PSB2 pressure switch opens or closes electrical circuit depending on pressure is dropping or rising.",
    bannerImage: blockimg2, //
    pdf: blockpdf2, //
    details: {
      TechnicalData: [
        "Mechanical Connection: : Side Flange",
        "Reproducibility : ±1% of adjusted pressure",
        // "Protective Class : IP55",
        "Working Temperature  :  -20°C ...+110°C [-4°F ...+230°F]",
        "Vibration Test (DIN EN 60068-2-27) :  20g (Test Time 30 min)",
        "Shock Test (DIN EN 60068-2-27:1993) :  30g",
        "Working Cycle:   5,000,000 cycles",
        "Viscosity :   Between 10 ... 800 mm /sec",
        //  "Maximum system pressure : 350 bar [5076 psi].",
        "Type :  PSB2",
      ],
    },
  },
  PressureSwitchBasemounting: {
    name: "Pressure Switch - Base mounting",
    description:
      "PSB3 pressure switch opens or closes electrical circuit depending on pressure is dropping or rising",
    bannerImage: blockimg3, //
    pdf: blockpdf3, //
    details: {
      TechnicalData: [
        "Mechanical Connection: : 1/4 BSP(F)",
        "Reproducibility : ±1% of adjusted pressure",
        // "Protective Class : IP55",
        "Working Temperature  :  -20°C ...+110°C [-4°F ...+230°F]",
        "Vibration Test (DIN EN 60068-2-27) :  20g (Test Time 30 min)",
        "Shock Test (DIN EN 60068-2-27:1993) :  30g",
        "Working Cycle:   5,000,000 cycles",
        "Viscosity :   Between 10 ... 800 mm /sec",
        //  "Maximum system pressure : 350 bar [5076 psi].",
        "Type :  PSB3",
      ],
    },
  },
  PressureSwitchWithManometer: {
    name: "Pressure Switch - With Manometer",
    description:
      "PSBM pressure switch opens or closes electrical circuit depending on pressure is dropping or rising. ",
    bannerImage: blockimg4, //
    pdf: blockpdf4, //
    details: {
      TechnicalData: [
        "Mechanical Connection: : 1/4 BSP(F)",
        "Reproducibility : ±1% of adjusted pressure",
        // "Protective Class : IP55",
        "Working Temperature  :  -20°C ...+110°C [-4°F ...+230°F]",
        "Vibration Test (DIN EN 60068-2-27) :  20g (Test Time 30 min)",
        "Shock Test (DIN EN 60068-2-27:1993) :  30g",
        "Working Cycle:   5,000,000 cycles",
        "Viscosity :   Between 10 ... 800 mm /sec",
        "Manometer : Ø63 - glycerine filled.",
        "Type :   PSBM",
      ],
    },
  },

  //temperature Switches
  TemperatureSwitchScrewin: {
    name: "Temperature Switch - Screw-in",
    description:
      "TST1 Series Bimetallic Thermostats opens or closes the electrical circuit depending on temperature is rising",
    bannerImage: tempimg1, //
    pdf: tempdf1, //
    details: {
      TechnicalData: [
        "Mechanical Connection: : 1/2” BSP, M22x1.5.",
        "Contact : NO or NC",
        // "Protective Class : IP55",
        "Rated Voltage : 250 VAC 50 Hz/60 Hz",
        "Rated Current : 10A",
        "Contact Resistance : 50mΩ",
        "Body Material : Brass",
        "Electrical Connection Type : Socket DIN 43650 2P+T",
        "Protecting Class : IP55",
        "Max. Media Temperature : +205°C [401°F]",
        "Working Cycle : 100,000 cycles",
        "Type : TST1",
      ],
    },
  },
  TemperatureSwitchScrewin2: {
    name: "Temperature Switch - Screw-in",
    description:
      "TST2 Series Bimetallic Thermostats opens or closes the electrical circuit depending on temperature is rising",
    bannerImage: tempimg2, //
    pdf: temppdf2, //
    details: {
      TechnicalData: [
        "Mechanical Connection: : 1/4” BSP, 1/2 BSP, M22x1.5",
        "Contact : NO or NC",
        // "Protective Class : IP55",
        "Rated Voltage : 250 VAC 50 Hz/60 Hz",
        "Rated Current : 10A",
        "Contact Resistance : 50mΩ",
        "Body Material : Brass",
        "Electrical Connection Type : Socket DIN 43650 2P+T",
        "Protecting Class : IP55",
        "Max. Media Temperature : +205°C [401°F]",
        "Working Cycle : 100,000 cycles",
        "Type : TST2",
      ],
    },
  },
};

const SwitchesDescription = () => {
  const { id } = useParams(); // Get the item id from the URL
  const item = itemDescriptions[id]; // Get the specific item's data
  const navigate = useNavigate(); // Hook for navigation

  if (!item) {
    return <div>Item not found</div>;
  }

  const handleEnquiryClick = () => {
    navigate("/Contact", { state: { productName: item?.name } }); // Pass item name via state
  };

  return (
    <div>
      <div className="product-dis-banner">
        <div className="wrapper">
          <div className="product-dis-content">
            <div className="product-dis-wrap">
              <div className="product-dis-1">
                <img src={item.bannerImage} alt={item.name} />
              </div>
              <div className="product-dis-2">
                <h1>{item.name}</h1>
                <h6>{item.description}</h6>
                <div style={{ marginTop: "20px" }}>
                  <a
                    href={item.pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button-3"
                  >
                    View PDF
                  </a>
                  <button
                    onClick={handleEnquiryClick}
                    className="button-2 m-l-1"
                  >
                    Enquire Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Description Section */}
      <div className="product-i-sec1-bg">
        <div className="wrapper">
          <div className="product-i-sec1-wrap">
            <div className="product-i-sec1-1">
              <h3>TECHNICAL DATA</h3>
              <ul>
                {item.details.TechnicalData.map((detail, index) => (
                  <li key={index}>{detail}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <GetinTouch />
    </div>
  );
};

export default SwitchesDescription;
