import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import productImg1 from "./images/product-img-1.png";
import GetinTouch from "./GetinTouch";

import comingSoon from "./comingsoonpdf";

//blader images
import standerd from "./images/AccBlader/Standard.png";
import ASME from "./images/AccBlader/ASME.png";
import LargeVolume from "./images/AccBlader/LargeVolume.png";
//Membrane -images
import Rechargable from "./images/Accmembarine/Rechargable.png";
import Sealed from "./images/Accmembarine/Sealed.png";
import Screwed from "./images/Accmembarine/Screwed.png";

//piston -imgaes
import Standard from "./images/AccPiston/Standard.png";
import Crimped from "./images/AccPiston/Crimped.png";

//Accessories - images
import SafetyBlock from "./images/AccAccessories/Safety.png";
import ChargingKit from "./images/AccAccessories/Charging.png";
import ChargingKitHighPressure from "./images/AccAccessories/ChargingKitHoghPressure.png";
import ChargingKit2 from "./images/AccAccessories/ChargingKit.png";
import BackupBottles from "./images/AccAccessories/BackupBottles.png";
import ChargingBlock from "./images/AccAccessories/ChargingBlock.png";
import MountingAccessories from "./images/AccAccessories/MountingAccessories.png";
import PistonAccumulator from "./images/AccAccessories/pistonAccumulator.png";

// om -images
import om2 from "./images/om-2.png";
import om3 from "./images/om-3.png";

//Accumulators Decription
import standerdpdf from "./images/AccBlader/blader.pdf";
import ASMEpdf from "./images/AccumlatorsPDF/Bladder-Accumulator-AS-11-001-R10.pdf";
import LargeVolumepdf from "./images/AccumlatorsPDF/Bladder-Accumulator-AS-11-001-R10.pdf";

//membrane pdfs
import rechargablepdf from "./images/AccumlatorsPDF/Diaphragm-Accumulator-AMW-13-001-R8.pdf";
import Sealedpdf from "./images/AccumlatorsPDF/Diaphragm-Accumulator-AMW-13-001-R8.pdf";
import Screwedpdf from "./images/Accmembarine/Rechargable.pdf";

//piston pdfs
import StandardRepairable from "./images/AccumlatorsPDF/Piston-Accumulator-AP-14-001-R5.pdf";
import CrimpedNonRepairable from "./images/AccumlatorsPDF/Piston-Accumulator-APC-14-002-Rev-0.pdf";

//Accessories
import SafetyBlockpdf from "./images/AccumlatorsPDF/Safety-Block-B-15-001-R4.pdf";
import ChargingKitpdf from "./images/AccumlatorsPDF/Charging-Kit-PC.pdf";
import Chargingkitadaptors from "./images/AccumlatorsPDF/Chargingkitadaptors.pdf";

import ChargingKitHighPressurepdf from "./images/AccumlatorsPDF/Charging-Kit-PC-HP-15-003-Rev-0.pdf";
import ChargingKit2pdf from "./images/AccumlatorsPDF/Charging-Kit-PCM.pdf";
import BackupBottlespdf from "./images/AccumlatorsPDF/Bladder-Accumulator-AS-11-001-R10.pdf";
import ChargingBlockpdf from "./images/AccumlatorsPDF/Charging-Block-BC-15-002-R3.pdf";
import MountingAccessoriespdf from "./images/AccumlatorsPDF/Mounting-Accessories-MA-15-006-R2.pdf";

import PistonAccumulatorLimitSwitchingDevicepdf from "./images/AccAccessories/PistonAccumulator.pdf";
//om-pdfs
import BladderAccumulatorpdf from "./images/BladderAccumulatorpdf.pdf";
import DiaphragmAccumulatorpdf from "./images/DiaphragmAccumulatorpdf.pdf";
import PistonAccumulatorpdf from "./images/PistonAccumulatorpdf.pdf";

const itemDescriptions = {
  //Bladder
  Standard: {
    name: "Standard",
    description:
      "A hydro-pneumatic accumulator is a device used specifically for storage of liquid under pressure.",
    bannerImage: standerd, //
    pdf: standerdpdf, //
    details: {
      TechnicalData: [
        "Design: Seamless / Fabricated shell.",
        "Design Standards:EN-14359, ASME.",
        "Nominal capacity  : 0.2 to 575 Ltrs.",
        "Max. Pressure (PS) :16-690 Bar",
        "Test Pressure (PT) : 1.43 x PS (PED Design) ,  1.3 x PS (ASME Design) ",
        "Temperature range : -20°C to +80°C (Standard) , -4°F to +212°F (Standard)  ",
        "Allowable pre.ratio (P2/P0) :4:1  ",
        "Max. Flow rates  Fluid :   Upto 1800 lpm",
        "Fluid Viscosity range :  10 to 400 cSt ",
        "Recommended viscosity : 32 cSt ",
        "Fluid contamination degree :Class 21/19/16 according to ISO 4406:1999 or better on request. ",
      ],
      Specifications: [
        {
          category: "Material of Construction",
          details: [
            { key: "Body", value: "Carbon Steel / Stainless Steel" },
            {
              key: "Bladder",
              value:
                "Nitrile (NBR) / Butyl (IIR) / Viton (FKM) / EPDM ..../ others listed on ordering code",
            },
          ],
        },
        {
          category: "Connections",
          details: [
            {
              key: "Gas Side",
              value:
                "5/8” UNF / 1/4” BSP / 5/16” UNEF/Vg8 / 7/8” UNF. /Others on request",
            },

            {
              key: "Fluid Side",
              value: "Female Threaded or Flanged. Others on request",
            },
          ],
        },
        {
          category: "Paint",
          details: [
            { key: "CS Accumulators", value: "Externally painted in RAL-5010" },
          ],
        },
      ],
    },
  },
  ASMEDesign: {
    name: "ASME Design",
    description:
      "A hydro-pneumatic accumulator is a device used specifically for storage of liquid under pressure.",
    bannerImage: ASME, //
    pdf: ASMEpdf, //
    details: {
      TechnicalData: [
        "Design: Seamless / Fabricated shell ",
        "Design Standards: ASME",
        "Nominal capacity  : 1/6 to 15 Gals",
        "Max. Pressure (PS) :1000-15000 PSI",
        "Test Pressure (PT) : 1.3 x PS",
        "Temperature range :  -20°C to +80°C (Standard)",
        "Allowable pre.ratio (P2/P0) : 4:1  ",
        "Max. Flow rates   :   Upto 1000 lpm ",

        "Fluid Viscosity range : 10 to 400 cSt ",
        "Recommended viscosity : 32 cSt ",
        "Fluid contamination degree : Class 21/19/16 /  according to ISO 4406:1999 / or better on request.",
        "Type : AA",
      ],
      Specifications: [
        {
          category: "Material of Construction",
          details: [
            { key: "Body", value: "Carbon Steel / Stainless Steel" },
            {
              key: "Bladder",
              value:
                ": Nitrile(NBR) / Butyl (IIR) / Viton (FKM) / EPDM .... , others listed on ordering code",
            },
          ],
        },
        {
          category: "Connections",
          details: [
            {
              key: "Gas Side",
              value:
                " 5/8” UNF / 1/4” BSP /  , 5/16” UNEF/Vg8 / 7/8” UNF. , Others on request.",
            },
            // { key: "Other Gas Side", value: "5/16” UNEF / Vg8 / 7/8” UNF" },
            {
              key: "Fluid Side",
              value: "Female Threaded or Flanged. Others on request",
            },
          ],
        },
        {
          category: "Pain",
          details: [
            {
              key: "CS Accumulators",
              value: "Externally painted in RAL-5010 , Others on request.",
            },
          ],
        },
      ],
    },
  },
  LargeVolume: {
    name: "Large Volume",
    description:
      "A hydro-pneumatic accumulator is a device used specifically for storage of liquid under pressure",
    bannerImage: LargeVolume, //
    pdf: LargeVolumepdf, //
    details: {
      TechnicalData: [
        "Design: Fabricated shell.",
        "Design Standards: EN-14359, ASME.",
        "Nominal capacity  : 150 to 575 Ltrs ",
        "Max. Pressure (PS) : 16-35 Bar (Higher on request).",
        "Test Pressure (PT) : 1.43 x PS (PED Design) ,  1.3 x PS (ASME Design) ",
        "Temperature range : -20°C to +80°C (Standard) , -4°F to +212°F (Standard)  ",
        "Allowable pre.ratio (P2/P0) :   4:1",
        "Fluid Viscosity range :  10 to 400 cSt ",
        "Recommended viscosity : 32 cSt ",
        "Fluid contamination degree :Class 21/19/16 according to ISO 4406:1999  or better on request. ",
        "Type :  ASLV",
      ],
      Specifications: [
        {
          category: "Material of Construction",
          details: [
            { key: "Body", value: "Carbon Steel / Stainless Steel" },
            {
              key: "Bladder",
              value:
                "Nitrile (NBR) / Butyl (IIR) /  Viton (FKM) / EPDM ..../ others listed on ordering code",
            },
          ],
        },
        {
          category: "Connections",
          details: [
            {
              key: "Gas Side",
              value:
                "5/8” UNF / 1/4” BSP /  5/16” UNEF/Vg8 / 7/8” UNF. /Others on request",
            },
            // { key: "Other Gas Side", value: "5/16” UNEF / Vg8 / 7/8” UNF" },
            {
              key: "Fluid Side",
              value: "Female Threaded or Flanged. Others on request",
            },
          ],
        },
        {
          category: "Paint",
          details: [
            {
              key: "CS Accumulators",
              value: "Externally painted in RAL-5010. ,  Others on request.",
            },
          ],
        },
      ],
    },
  },

  //Membrane
  Rechargable: {
    name: "Rechargable ",
    description:
      "A hydro-pneumatic accumulator is a device used specifically for storage of liquid under pressure.",
    bannerImage: Rechargable, //
    pdf: rechargablepdf, //
    details: {
      TechnicalData: [
        "Design: Welded shell, non-repairable",
        "Design Standards:EN-14359.",
        "Nominal capacity  : 0.075 to 5 Ltrs.",
        "Max. Pressure (PS) : 40-350 Bar.",
        "Test Pressure (PT) :  1.43 x PS",
        "Temperature range :  -20°C to +80°C (Standard) ",
        "Allowable pre.ratio (P2/P0) : 1:4 to 1:8  ",
        "Max. perm. precharge pressure P 0-max : 130 bar.",

        "Fluid Viscosity range :  10 to 400 cSt",
        "Recommended viscosity : 32 cSt ",
        "contamination degree : Class 21/17/14 acc. to ISO 4406 or better on request.",
        "Type : AMW-M",
      ],
      Specifications: [
        {
          category: "Material of Construction",
          details: [
            {
              key: "Body",
              value:
                "Carbon Steel-Painted or Low Temp Carbon Steel or Stainless Steel",
            },
            {
              key: "Diaphragm",
              value:
                " Nitrile(NBR) or  Epichlorohydrin(ECO) or   Viton (FKM). ",
            },
            { key: "Dia. Plug", value: "Plastic (Standard) or CS or SS." },
          ],
        },
        {
          category: "Connections",
          details: [
            {
              key: "Gas Side",
              value: " M28x1.5(M) or  5/16” UNEF/Vg8. ",
            },
            // { key: "Other Gas Side", value: "5/16” UNEF / Vg8 / 7/8” UNF" },
            {
              key: "Fluid Side",
              value:
                " Female Threaded or  Combination Thread (M+F) or SAE Ports , Others on request.",
            },
          ],
        },
        {
          category: "Paint",
          details: [
            { key: "CS Accumulators", value: "Externally painted in RAL-5010" },
          ],
        },
      ],
    },
  },
  SealedNonRechargable: {
    name: "Sealed (Non Rechargable)",
    description:
      "A hydro-pneumatic accumulator is a device used specifically for storage of liquid under pressure",
    bannerImage: Sealed, //
    pdf: Sealedpdf, //
    details: {
      TechnicalData: [
        "Design:  Welded shell, non-repairable",
        "Design Standards: EN-14359",
        "Nominal capacity  :  0.075 to 1.4 Ltrs. ",
        "Max. Pressure (PS) : 40-250 Bar.",
        "Test Pressure (PT) :  1.43 x PS",
        "Temperature range :  : -20°C to +80°C (Standard)",
        "Permissible Pre. Ratio(P2:P0): 1:8 ",
        "Max. perm. precharge pressure P 0-max : 130 bar.",

        "Fluid Viscosity range : 10 to 400 cSt ",
        "Recommended viscosity : 32 cSt ",
        "Contamination degree : Class 21/17/14 acc. to ISO 4406  or better on request. ",
        "Type : AMW-P",
      ],
      Specifications: [
        {
          category: "Material of Construction",
          details: [
            {
              key: "Body",
              value:
                "Carbon Steel-Painted or  Low Temp Carbon Steel or Stainless Steel.",
            },
            {
              key: "Diaphragm",
              value: "Nitrile(NBR) or  Epichlorohydrin(ECO) or Viton (FKM). ",
            },
            { key: "Dia. Plug", value: "Plastic (Standard) or CS or SS." },
          ],
        },
        {
          category: "Connections",
          details: [
            {
              key: "Gas Side",
              value: "Dia 8 - Sealed type fixed precharge ",
            },
            // { key: "Other Gas Side", value: "5/16” UNEF / Vg8 / 7/8” UNF" },
            {
              key: "Fluid Side",
              value:
                " Female Threaded or  Combination Thread (M+F) or  SAE Ports.  , Others on request.",
            },
          ],
        },
      ],
    },
  },
  ScrewedRepairable: {
    name: "Screwed (Repairable)",
    description:
      "A hydro-pneumatic accumulator is a device used specifically for storage of liquid under pressure.",
    bannerImage: Screwed, //
    // pdf: Screwedpdf, //
    pdf: comingSoon,
    details: {
      TechnicalData: [
        "Design: Screwed Shell, Repairable",
        "Design Standards: EN-14359, ASME",
        "Nominal capacity  : 0.1 to 2.5 Ltrs ",
        "Max. Pressure (PS) :  350 Bar",
        "Test Pressure (PT) : 1.43 x PS (PED Design) ,  1.3 x PS (ASME Design) ",
        "Temperature range : -20°C to +80°C (Standard) , -4°F to +212°F (Standard)  ",
        "Allowable pre.ratio (P2/P0) :  6:1 ",
        //  "Max. Flow rates   :   ",
        //  "Fluid :",
        //  "Fluid Viscosity range :  ",
        //  "Recommended viscosity :  ",
        //  "Fluid contamination degree : ",
        "Type :AM",
      ],
      Specifications: [
        {
          category: "Material of Construction",
          details: [
            { key: "Body", value: "Stainless Steel" },
            { key: "Diaphragm", value: "Nitrile /Viton /EPDM" },
          ],
        },
        {
          category: "Connections",
          details: [
            { key: "Gas Side", value: "5/8” UNF (M)" },
            // { key: "Other Gas Side", value: "5/16” UNEF / Vg8 / 7/8” UNF" },
            { key: "Fluid Side", value: "M18 x 1.5 (F), 1/2” BSP, 3/4” BSP " },
          ],
        },
      ],
    },
  },

  //Piston
  StandardRepairable: {
    name: "Standard Repairable",
    description:
      "A hydro-pneumatic piston accumulator is a device used specifically for storage of liquid under pressure.",
    bannerImage: Standard, //
    pdf: StandardRepairable, //
    details: {
      TechnicalData: [
        "Design:Floating Piston, repairable ",
        "Design Standards: EN-14359, ASME",
        "Nominal capacity : 0.25 to 1500 Ltrs.",
        "Max. Pressure (PS) :  220 ~ 1000 Bar",
        "Test Pressure (PT) : 1.43 x PS (PED Design) ,  1.3 x PS (ASME Design) ",
        "Temperature range : -20°C to +80°C (Standard)  ",
        "Allowable pre.ratio (P2/P0) :  8:1 ",
        //  "Max. Flow rates   :   ",

        "Fluid Viscosity range : 10 to 400 cSt  ",
        "Recommended viscosity :  32 cSt",
        "Fluid contamination degree :  Class 20/18/13 ,  according to ISO 4406:1999 ,  or better on request.",
        "Type : AP ",
      ],
      Specifications: [
        {
          category: "Material of Construction",
          details: [
            { key: "Body", value: "Carbon Steel / Stainless Steel." },
            {
              key: "Piston",
              value: "Aluminium Alloy / SG Iron / Stainless Steel",
            },
            {
              key: "Piston Seals",
              value: " PU / PTFE / Viton / EPDM ,  others on reqeust.",
            },
          ],
        },
        {
          category: "Connections",
          details: [
            {
              key: "Gas Side",
              value:
                "5/8” UNF / 1/4” BSP / 5/16” UNEF/Vg8.  Others on request.",
            },
            // { key: "Other Gas Side", value: "5/16” UNEF / Vg8 / 7/8” UNF" },
            {
              key: "Fluid Side",
              value: "Female Threaded or Flanged. Others on request",
            },
          ],
        },
        {
          category: "Paint",
          details: [
            {
              key: "CS Accumulators",
              value: " Externally painted in RAL-5010. , Others on request.",
            },
          ],
        },
      ],
    },
  },
  CrimpedNonRepairable: {
    name: "Crimped (Non-Repairable)",
    description:
      "A hydro-pneumatic piston accumulator is a device used specifically for storage of liquid under pressure",
    bannerImage: Crimped, //
    pdf: CrimpedNonRepairable, //
    details: {
      TechnicalData: [
        "Design: Floating Piston, non-repairable",
        "Design Standards: EN-14359, ASME",
        "Nominal capacity  :  0.1 to 8 Ltrs. ",
        "Max. Pressure (PS) :250 bar [3625 psi].",
        "Test Pressure (PT) : 1.43 x PS (PED Design) ,  1.3 x PS (ASME Design) ",
        "Temperature range : -20°C to +80°C (Standard) , -4°F to +212°F (Standard)  ",
        "Allowable pre.ratio (P2/P0) :8:1  ",
        //  "Max. Flow rates   :   ",

        "Fluid Viscosity range : 10 to 400 cSt ",
        "Recommended viscosity : 32 cSt ",
        "Fluid contamination degree :  Class 20/18/13 , according to ISO 4406:1999 ,  or better on request.",
        "Type : APC",
      ],
      Specifications: [
        {
          category: "Material of Construction",
          details: [
            { key: "Body", value: "Carbon Steel " },
            { key: "Piston", value: "Aluminium Alloy." },
            { key: "Piston Seals ", value: " PU / PTFE. , Others on request" },
          ],
        },
        {
          category: "Connections",
          details: [
            {
              key: "Gas Side",
              value:
                " 5/8” UNF / 1/4” BSP / M28x1.5 /  5/16” UNEF/Vg8. ,  Others on request.",
            },
            // { key: "Other Gas Side", value: "5/16” UNEF / Vg8 / 7/8” UNF" },
            {
              key: "Fluid Side",
              value: "Female Threaded. ,  Others on request.",
            },
          ],
        },
        {
          category: "Paint",
          details: [
            {
              key: "CS Accumulators",
              value: "Externally painted in RAL-5010 , Others on request.",
            },
          ],
        },
      ],
    },
  },

  //Accessories
  SafetyBlock: {
    name: "Safety Block",
    description:
      "The Safety Block is installed on the liquid side of the accumulator and functions as a pressure restricting safety valve for the accumulator and also used for isolating the accumulator from the  hydraulic system",
    bannerImage: SafetyBlock, //
    pdf: SafetyBlockpdf, //
    details: {
      TechnicalData: [
        "Nominal flow rates @ 6m/s : 30 to 290 lpm ",
        "Shut-off valve : Ball type",
        "Diameter of inlet port : 10, 20, 25, 32 mm",
        "Max. Pressure (PS) :420 Bar.",
        "Test Pressure (PT) : 1.43 x PS ",
        "Temperature range :   -40°C to +150°C ,  (+65°C with solenoid valve).",
        //  "Allowable pre.ratio (P2/P0) :  ",
        "Relief valve Port Dia : 10 mm",
        "Set pressure : 5 to 420 bar.",
        "Fluid Viscosity range :   10 to 400 cSt",
        "Recommended viscosity : 32 cSt ",
        "Fluid contamination degree : Class 21/19/16 , according to ISO 4406:1999 ",
        "Type :B ",
      ],
      Specifications: [
        "Discharge : Manual and Electric",
        {
          category: "Solenoid valve",
          details: [
            {
              key: "Power voltage",
              value: "24VDC / 110 VDC / 220VDC ,  24VAC / 110 VAC / 220VAC",
            },
            { key: "Power consumption", value: "26W" },
            {
              key: "Protection",
              value:
                ": IP65 in compliance with ,DIN 40050 connector in , compliance with DIN 43650 , type A 2 poles + earthling with ,  AC voltage; internal connector , has bridge rectifier",
            },
            {
              key: "Body material ",
              value:
                "Phosphated Carbon Steel , Nickle Coated Carbon Steel , Stainless Stee",
            },
            {
              key: "Valve material ",
              value: "Phosphated Carbon Steel (Stainless Steel on request)",
            },
            {
              key: "Seals",
              value:
                "Nitrile / Viton / Butyl / EPDM ,  Low temperature nitrile ,  Hydrogenated nitrile",
            },
            // { key: "Bladder", value: "Nitrile (NBR) / Butyl (IIR) / others listed on ordering code" }
          ],
        },
      ],
    },
  },
  ChargingKit: {
    name: "Charging Kit - Plunger type",
    description:
      "Used for Nitrogen filling of accumulators with Plunger type gas fill valve",
    bannerImage: ChargingKit, //
    pdf: ChargingKitpdf, //
    details: {
      TechnicalData: [
        "Max. Working pressure : 400 Bar",
        "Accumulator Connection : 5/8” UNF(Standard) , Ø7.7x1/32” -Vg8 (5/16” UNEF), 7/8” UNF, 1/4” BSP",
        "Bottle Connection : Standard - 5/8” BSP(Internal others on request)",
        "Pressure gauges : Ø63. Connection - 1/4” BSP(M) , LP- 70 kg/cm2. HP- 280 kg/cm2 as standard others on request)",
        "Weight : 1.75 kg (carrying case included)",
        "Type : PC",
      ],
      Specifications: ["content will be soon...."],
    },
  },

  Chargingkitadaptors: {
    name: "Charging Kit Adaptors",
    description: "Charging Kit Adaptors",
    bannerImage: ChargingKit, //
    pdf: Chargingkitadaptors, //
    details: {
      TechnicalData: [
        "Max. Working pressure : ",
        "Accumulator Connection : ",
        "Bottle Connection :",
        "Pressure gauges : ",
        "Weight : ",
        "Type : ",
      ],
      Specifications: ["content will be soon...."],
    },
  },
  ChargingKitHighPressure: {
    name: "Charging Kit – High Pressure",
    description:
      "Used for Nitrogen filling of accumulators with Plunger type gas fill valve",
    bannerImage: ChargingKitHighPressure, //
    pdf: ChargingKitHighPressurepdf, //
    details: {
      TechnicalData: [
        "Max. Working pressure : 690 Bar",
        "Accumulator Connection : 5/8” UNF , 1/4” BSP",
        "Bottle Connection : Standard - 5/8” BSP (others on request)",
        "Pressure gauges : Ø63. Connection - 1/4” BSP(M) , HP - 700 bar. LP - 400 bar (others on request)",
        "Weight : 1.95 kg (carrying case included)",
        "Type : PC",
      ],
      Specifications: ["content will be updated soon...."],
    },
  },
  ChargingKit2: {
    name: "Charging Kit -  Allen screw type",
    description:
      "Used for Nitrogen filling of accumulators with Allen screw type gas fill valve",
    bannerImage: ChargingKit2, //
    pdf: ChargingKit2pdf, //
    details: {
      TechnicalData: [
        "Max.Working pressure : 400Bar",
        "AccumulatorConnection : M28 x 1.5",
        "BottleConnection : Standard - 5/8”BSP(Internal) (others on request)",
        "Pressure gauges: Ø63.Connection - 1/4”BSP(M) , LP- 70 kg/cm2. , HP- 280 kg/cm2  (others on request)",
        "Weight : 1.8 kg (carrying case included)",
      ],
      Specifications: ["content will be updated soon...."],
    },
  },
  BackupBottles: {
    name: "Backup Bottles",
    description:
      "Used in conjunction with transfer accumulators in applications where large stored volume is desired at small pressure differentials",
    bannerImage: BackupBottles, //
    pdf: BackupBottlespdf, //
    details: {
      TechnicalData: [
        "Design: Seamless ",
        "Design Standards: EN-14359, ASME",
        "Nominal capacity  :  3 to 75 Ltrs.",
        "Max. Pressure (PS) :  360 Bar.",
        "Test Pressure (PT) : 1.43 x PS (PED Design) ,  1.3 x PS (ASME Design) ",
        "Temperature range : -20°C to +80°C (Standard) , -4°F to +212°F (Standard)  ",

        "Type : BA/BB/BD",
      ],
      Specifications: [
        {
          category: "Material of Construction",
          details: [{ key: "Body", value: "Carbon Steel / Stainless Steel" }],
        },
        {
          category: "Connections",
          details: [
            {
              key: "Systems Side",
              value: "3/4” BSP,  1 1/2”  BSP, 2 1/2” BSP (Others on request)",
            },
            // { key: "Other Gas Side", value: "5/16” UNEF / Vg8 / 7/8” UNF" },
            {
              key: "Drain Side",
              value:
                "3/4” BSP,  1/2”  BSP, 3/4” BSP and 1” BSP (Others on request)",
            },
          ],
        },
        {
          category: "Paint",
          details: [
            {
              key: "CS Accumulators",
              value: "Full body externally painted in RAL-7035",
            },
          ],
        },
      ],
    },
  },
  ChargingBlock: {
    name: "Charging Block",
    description:
      "The Gas Charging Block is installed on the gas side of transfert accumulator and used for connecting the accumulator to backup bottles ensuring free flow of nitrogen.",
    bannerImage: ChargingBlock, //
    pdf: ChargingBlockpdf, //
    details: {
      TechnicalData: [
        "Port size : 25mm [1.00 inch], 32mm [1.25 inch]",
        "Max. Pressure (PS) : 400 bar [5800 psi]",
        "Test Pressure (PT) : 1.43 x PS (as per CE/PED) , 1.3 x PS (as per ASME)",
        "Temperature range : -20°C to +80°C (Standard) , -4°F to +176°F (Standard)",
      ],
      Specifications: [
        {
          category: "Material of Construction",
          details: [
            { key: "Body", value: "Carbon Steel externally phosphated." },
            { key: "Ball", value: "Stainless Steel." },
            { key: "Bowl", value: " POM / Nitrile. , Others on request" },
          ],
        },
        {
          category: "Standard Connections",
          details: [
            {
              key: "Port A (Accumulator)",
              value: "3/4” BSP(F) to 1 1/2” BSP(F)",
            },
            { key: "Port B (Bottles)", value: "3/4” BSP(F) to 1 1/2” BSP(F)" },
            { key: "Port M (Pr. Gauge)", value: "1/4” BSP(F)" },
            {
              key: "Port PC (Gas Valve)",
              value: "5/8” UNF(M) (others on request)",
            },
            { key: "Shut-off Valve R ", value: " DN25/DN32" },
          ],
        },
      ],
    },
  },
  MountingAccessories: {
    name: "Mounting Accessories",
    description:
      "The mounting accessories (Brackets & Clamps) are very critical components in a hydraulic system and ensure the accumulator is properly and rigidly clamped.",
    bannerImage: MountingAccessories, //
    pdf: MountingAccessoriespdf, //
    details: {
      TechnicalData: [
        "Clamp Assy. : Galvanised CS + EPDM (SS on request)",
        "Bracket Assy. : Galvanised CS + Nitrile (SS on request)",
        "Support Ring : Nitrile",
        "Fasteners : CS - Gr 8.8 (Gr.8 for nut) (SS on request)",
      ],
      Specifications: ["content will be updated soon...."],
    },
  },
  PistonAccumulatorLimitSwitchingDevice: {
    name: "Piston Accumulator – Limit Switching Device",
    description:
      "The ES type limit switching device is mounted on the gas side of piston accumulator and used for monitoring position of the piston during operation ",
    bannerImage: PistonAccumulator, //
    pdf: PistonAccumulatorLimitSwitchingDevicepdf, //
    details: {
      TechnicalData: [
        "Stroke : Max. 100 mm including spring reset",
        "Switches : Max. TWO Switches - NO / NC / Bistable",
        "Piston Speed : Max. 0.5 m/s in charging stroke",
        "Suggested Installation Position : Vertical",
      ],
      Specifications: ["Contetn will be updated soon..."],
    },
  },

  //Operator Manuals:
  BladderAccumulator: {
    name: " Operator Manual - Bladder Accumulator",
    description: "",
    bannerImage: standerd, //
    pdf: BladderAccumulatorpdf,
    details: {
      TechnicalData: ["content will be updated soon...."],
      Specifications: ["content will be updated soon...."],
    },
  },
  DiaphragmAccumulator: {
    name: "Operator Manual - Diaphragm Accumulator",
    description: "",
    bannerImage: om2, //
    pdf: DiaphragmAccumulatorpdf, //
    details: {
      TechnicalData: ["content will be updated soon...."],
      Specifications: ["content will be updated soon...."],
    },
  },
  PistonAccumulator: {
    name: "Operator Manual - Piston Accumulator",
    description: "",
    bannerImage: om3, //
    pdf: PistonAccumulatorpdf, //
    details: {
      TechnicalData: ["content will be updated soon...."],
      Specifications: ["content will be updated soon...."],
    },
  },
};

const AccumlatorsDescription = () => {
  const { id } = useParams(); // Get the item id from the URL
  const item = itemDescriptions[id]; // Get the specific item's data
  const navigate = useNavigate(); // Hook for navigation

  if (!item) {
    return <div>Item not found</div>;
  }

  const handleEnquiryClick = () => {
    navigate("/Contact", { state: { productName: item?.name } }); // Pass item name via state
  };

  return (
    <div>
      <div className="product-dis-banner">
        <div className="wrapper">
          <div className="product-dis-content">
            <div className="product-dis-wrap">
              <div className="product-dis-1">
                <img src={item.bannerImage} alt={item.name} />
              </div>
              <div className="product-dis-2">
                <h1>{item.name}</h1>
                <h6>{item.description}</h6>
                <div style={{ marginTop: "20px" }}>
                  <a
                    href={item.pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button-3"
                  >
                    View PDF
                  </a>
                  <button
                    onClick={handleEnquiryClick}
                    className="button-2 m-l-1"
                  >
                    Enquire Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Description Section */}
      <div className="product-i-sec1-bg">
        <div className="wrapper">
          <div className="product-i-sec1-wrap">
            <div className="product-i-sec1-1">
              <h3>TECHNICAL DATA</h3>
              <ul>
                {item.details.TechnicalData.map((detail, index) => (
                  <li key={index}>{detail}</li>
                ))}
              </ul>
            </div>

            <div className="product-i-sec1-1">
              <h3>SPECIFICATIONS</h3>
              {item.details.Specifications.map((spec, index) =>
                typeof spec === "string" ? (
                  <p key={index}>{spec}</p>
                ) : (
                  <div key={index}>
                    <h4>{spec.category}</h4>
                    <ul>
                      {spec.details.map((detail, detailIndex) => (
                        <li key={detailIndex}>
                          <strong>{detail.key}:</strong> {detail.value}
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccumlatorsDescription;
